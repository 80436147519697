<template>
	<div class="row">
		<div class="col-lg-12 col-xxl-12 order-5 order-xxl-5">
			<SiteList fullpage />
		</div>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
	components: {
		SiteList: () => import('@/view/content/widgets/dashboard/SiteList.vue'),
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Site List' }]);
	},
};
</script>

<style></style>
